.tab-active {
  background-color: #204C78 !important;
  color: white !important;
  font-weight: bold !important;
}


.tab-inactive {
  background-color : rgba(109, 109, 109, 0) !important;
  border: 1px solid #cccccc !important;
  color: #204C78 !important;
}


.summary-table {
  flex: flex-grow;
}


.summary-table-cell {
  width: 33.33%;
}


.summary-table-cell-text {
  background-color: rgba(237, 237, 237, 0.44);
}

.header-box {
  padding-bottom: '30px';
  background-color: 'background.paper'
}