/* General Styles */
.header {
  display: flex;
  justify-content: flex-start;
  /* Align items from the start (left) */
  align-items: center;
  padding: 20px;
  background-color: rgba(32, 77, 121, 0.00);
  color: #204C78 !important;
}

/* Logo Styling */
.logo {
  margin-right: 150px;
  /* Adds spacing to the right of the logo */
}

/* Responsive Styles */
@media (max-width: 1000px) {
  .header {
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center align items */
  }

  .logo {
    margin: 0 auto 20px auto;
    /* Center logo with margin adjustments */
  }
}